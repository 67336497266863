import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class ToggleSwitchComponent implements OnInit {

  @Input() labels: string[] = ['true', 'false']
  @Output() toggleStatus = new EventEmitter<boolean>();


  status: boolean = false;

  constructor() { 
  }

  ngOnInit(): void {
  }


  toggleChange() {
    this.toggleValue(this.status)
    let front = document.getElementById('frontLabel')
    let back = document.getElementById('backLabel')
    if(this.status === false) {
      front?.classList.add('active')
      back?.classList.add('inactive')
      front?.classList.remove('inactive')
      back?.classList.remove('active')
    } else {
      front?.classList.add('inactive')
      back?.classList.add('active')
      front?.classList.remove('active')
      back?.classList.remove('inactive')
    }
  }

  toggleValue(value: boolean) {
    this.toggleStatus.emit(!value);
  }

  forceChange(value: boolean): void {
    this.status = value;
    this.toggleChange()
  };

}
